import React from 'react'
import styled from 'styled-components'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import SEO from './../components/seo'
import Layout from './../components/layout'
import Footer from './../components/footer'

import { theme, media } from './../helpers/theme'
import wave from './../images/404-wave.svg'
import plane from './../images/crashed-plane.svg'
import { Link } from 'gatsby'

const Content = styled.div`
  margin: 0 auto;
  align-self: center;
  width: 100%;
  max-width: 1280px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  margin-top: -100px;

  ${media('md')} {
    margin-top: -100px;
  }

  ${media('lg')} {
    margin-top: -150px;
  }
`

const ColorSpacer = styled.div`
  background-color: ${theme.colors.primary};
  height: 100px;

  ${media('sm')} {
    height: 75px;
  }

  ${media('md')} {
    height: 50px;
  }

  ${media('lg')} {
    height: 0px;
  }
`

const Title = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  text-align: center;
  font-size: ${theme.font['2xl']};
  ${media('md')} {
    font-size: ${theme.font['3xl']};
    text-align: initial;
  }
  ${media('lg')} {
    font-size: ${theme.font['4xl']};
  }
`

const Description = styled.p`
  color: #313944;
`

const Button = styled(Link)`
  font-family: Poppins, serif;
  margin: 0 ${theme.space[2]}px;
  background-color: ${theme.colors.accent};
  border: none;
  border-radius: 5px;
  padding: ${theme.space[2]}px ${theme.space[3]}px;
  color: ${theme.colors.font.black};
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
`

const NotFound = () => (
  <Layout isWhiteLogo>
    <SEO title='Not Found' pageType='home' flowType='none' path='404' />
    <ColorSpacer />
    <div style={{ marginTop: -5, position: 'relative', zIndex: 30 }}>
      <img src={wave} alt='design wave' style={{ width: '100%' }} />
      <Content>
        <img src={plane} alt='crashed paper plane' />
        <Title>Oops...</Title>
        <Description>
          Sorry, the page you're trying to visit could not be found.
        </Description>
        <Button
          to='/'
          data-element-location={DataElementLocations.BODY}
          data-element-label='404.button'
          data-element-id='404-page-take-me-home'
        >
          Take Me Home
        </Button>
      </Content>
    </div>

    <Footer />
  </Layout>
)

export default NotFound
